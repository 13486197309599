import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="EE (Commture 365) Customer Story - Rotaready"
          description="Since getting started with Rotaready, Commture 365 have been able to better deploy over 28 hours every week and achieved an ROI of 321%. Here's how..."
          url="customer-stories/retail-ee-commture365"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Commture 365 store"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Retail" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Commture 365 logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since getting started with Rotaready, Commture 365 have been able to better deploy over 28 hours every week and achieved an ROI of 321%. Here's how..." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="14" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="110+" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="321%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="28 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Commture 365 - an EE franchise" />
              </Content>

              <Content>
                <TextBlock text="Commture 365 is one of EE's largest franchisees, with 14 stores across the West Midlands, Northamptonshire and the North West." />
              </Content>

              <Content>
                <TextBlock text="Back when they had just 2 stores, Commture 365 selected a staff scheduling solution which the team used for 5 years. However the system wasn't scalable, and as the business expanded it became more and more apparent that it was no longer fit for purpose. So in February 2020 the team at Commture 365 set about finding a workforce management partner that could grow with the business and help take its staff scheduling to the next level." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="The hunt for more than just staff scheduling" />
              </Content>

              <Content>
                <TextBlock text="The business didn't just want to perfect its employee scheduling, Commture 365 was after a solution that would combine the functionality of the multiple systems they were using across the business at the time." />
              </Content>

              <Content>
                <TextBlock text="The dream was to bring HR, staff scheduling, time & attendance and reporting into one system; reducing the duplication of work that came with onboarding new employees and keeping staff records up to date. It needed to be easy for staff to clock in and out too, in order to improve time management and payroll accuracy." />
              </Content>

              <Content>
                <TextBlock text="It was imperative that their chosen solution could grow with the business and meet the demands of every stakeholder - from admin and HQ, to sales staff and managers. And, although great software is important, a good relationship with the partner of choice would be instrumental in Commture 365's decision." />
              </Content>

              <Content>
                <Blockquote
                  text="A good relationship with our partner of choice was key, and instrumental to our decision. There is no doubt that's what we've got with Rotaready. Our Customer Success Manager and the wider support team answer our questions in minutes rather than days through phone, email and even live chat within Rotaready itself. This made everything from the research and implementation, to the ongoing use of Rotaready, a seamless and stress-free experience."
                  attribution="Minesh Solanki, Managing Director"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="EE sales assistant helping a customer"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Tried and tested" />
              </Content>

              <Content>
                <TextBlock text="Commture 365 knew exactly what they were looking for. After numerous Google searches and some thorough research, they trialled Rotaready in parallel with another popular staff rota system, over a period of two weeks." />
              </Content>

              <Content>
                <TextBlock text="The teams rigorously tested all areas of both solutions, to ensure they chose the staff scheduling software that was the right fit for every area of their business. They also challenged how each solution could scale as they grow." />
              </Content>

              <Content>
                <Blockquote
                  text="We involved both our HQ and management team in the testing process to ensure we thoroughly tested all areas of the product. Rotaready stood out on all levels. The intuitive UI and extensive range of features combined with the clear flexibility and scalability of the software, left no doubt in our minds that Rotaready was the perfect fit for our retail business."
                  attribution="Minesh Solanki, Managing Director"
                />
              </Content>

              <Content>
                <TextBlock text="The team at Commture 365 decided Rotaready was the clear winner and in May 2020 implemented Rotaready across all stores in a single fortnight." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A solution for everyone" />
              </Content>

              <Content>
                <TextBlock text="Each department is making the most of Rotaready and its extensive range of features, to help make their working lives easier. Building and signing off staff rotas is now easier than ever for management teams and those on the shop floor are loving the visibility and control they have over their holiday and upcoming shifts, thanks to the Rotaready app. Time tracking is consistently accurate as the team uses the Rotaready app to clock in and out, meaning any discrepancies and mistakes in payroll are a distant memory." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="EE store interior"
              />
            </Section>

            <Section>
              <PopQuote text="Adopting so many of Rotaready's features has enabled Commture 365 to better deploy over 28 hours each week and achieve an ROI of over 320%." />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="There are always challenges along the way when you implement a new solution but the fiercely proactive team at Rotaready were always on hand to help us with any teething issues we had, meaning we could get Rotaready up and running across all 14 stores in just two weeks."
                  attribution="Minesh Solanki, Managing Director"
                />
              </Content>

              <Content>
                <TextBlock text="Rotaready will continue to work closely with the team at Commture 365 to ensure they get the very best from its software as the franchise grows." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready are the perfect example of where quality prevails. They may not have been the most well known or cheapest solution we looked at, but their product and support was consistently impressive. We're already seeing a great return on our investment in Rotaready and are excited to see what comes next on our journey together."
                  attribution="Minesh Solanki, Managing Director"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_commture365"
                label="rotaready.com/customer-stories/retail-ee-commture365"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-EE-Commture365.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/ee-commture365/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/ee-commture365/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/ee-commture365/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/ee-commture365/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
